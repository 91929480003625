import React, { useContext, useEffect } from "react";
import "./home.css";
import Footer from "../component/footer";
import Header from "../component/Header";
import { blogs } from "../App";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function Blog() {
  const bloglist = useContext(blogs);
  const history = useHistory();
  const Viewpage = (item) => {
    history.push({
      pathname: `/Blogdetails/${item._id}/${String(item.title)
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, "-")}`,
    });
  };
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 50);
  }, []);
  useEffect(() => {
    document.title = "Blog-SmartCarrots";
  }, []);
  return (
    <>
      <Header />

      <section className="blog-detail-container blog">
        <div className="container">
          <div className="blogname">
            <div className="text-center">
              <h2 className="heading">Blog</h2>
            </div>
          </div>
          <div className="row px-3">
            {bloglist?.map((item) => {
              return (
                <div
                  className="col-lg-4 col-md-6 mb-3 d-flex justify-content-center"
                  onClick={() => Viewpage(item)}
                >
                  <div className="blog-body text-center">
                    <div className="blog-img-cont">
                      <img src={item?.image} alt="" className="blog-img" />
                    </div>
                    <h4 className="pt-3 px-5">{item?.title}</h4>
                    <p className="pt-3 px-5">{item.short_description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
