import React, { useState } from "react";

const Timer = () => {
  const [days, setDay] = useState("");
  const [hours, setHour] = useState("");
  const [minutes, setMinute] = useState("");
  const [seconds, setSecond] = useState("");

  let countDownDate = new Date("may 1, 2024 23:59:59").getTime();

  let x = setInterval(function () {
    let now = new Date().getTime();

    let distance = countDownDate - now;

    let days = Math.floor(distance / (1000 * 60 * 60 * 24));
    let hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

    setDay(days);
    setHour(hours);
    setMinute(minutes);
    setSecond(seconds);

    if (distance < 0) {
      clearInterval(x);
      document.getElementById("days").innerHTML = "EXPIRED";
    }
  }, 1000);
  return (
    <div>
      <div className="timer">
        <div className="row justify-content-center f-wrap">
          <div className="col-xxl-7 col-xl-8 col-lg-9 col-11">
            <div className="row justify-content-between timer-body">
              <div className="col-md-3 col-6 mb-5">
                <div className="timer-cont  ">
                  <div>
                    <h2 id="days">{days}</h2>

                    <p>Days</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-6 mb-5">
                <div className="timer-cont ">
                  <div>
                    <h2 id="hour">{hours}</h2>
                    <p>Hours</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-6 mb-5">
                <div className="timer-cont ">
                  <div>
                    <h2 id="Mintues">{minutes}</h2>
                    <p>Minutes</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-6 mb-5">
                <div className="timer-cont ">
                  <div>
                    <h2 id="Seconds">{seconds}</h2>
                    <p>Seconds</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timer;
