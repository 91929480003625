import React, { useContext, useEffect, useState } from "react";
import LexicalEditor from "../LexicalEditor/index";
import { useParams } from "react-router-dom";
import Header from "../component/Header";
import Footer from "../component/footer";
import { blogs } from "../App";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function BlogDetails() {
  const history = useHistory();

  const bloglist = useContext(blogs);
  const param = useParams();
  const [detail, setdetail] = useState({});

  const details = async () => {
    try {
      const response = await fetch(
        `https://smartcarrots.com/user/get_blog_by_id?blog_id=${param.id}`
      );

      const resp = await response.json();
      console.log(resp);
      setdetail(resp.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };

  const Viewpage = (item) => {
    history.push({
      pathname: `/Blogdetails/${item._id}/${String(item.title)
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, "-")}`,
    });
  };

  useEffect(() => {
    details();
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 50);
  }, [history.location.pathname]);
  useEffect(() => {
    document.title = `${detail?.title}`;
  }, [detail]);
  console.log(detail.title);
  return (
    <div>
      <Header />
      <div>
        <section className="container-fluid px-sm-5 px-3 pt-5">
          <div className="row justify-content-between">
            <div className="col-xl-8 col-lg-7 blog-details ffm1">
              <div>
                <h3 className="py-3 fw-bold">{detail.title}</h3>
                <div>
                  <img src={detail.image} alt="" className="img-fluid" />
                </div>
                <div className="py-4">
                  <LexicalEditor
                    initialEditorState={detail?.details_description}
                    key={detail?.details_description}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5 col-md-8 col-sm-9">
              {bloglist?.slice(0, 6)?.map((item) => {
                return (
                  <div
                    className="blog-item mt-3 py-3 px-3"
                    onClick={() => Viewpage(item)}
                  >
                    <div className="d-flex align-items-center ">
                      <div className="">
                        <img src={item.image} alt="" className="" />
                      </div>
                      <div className="ps-2 pt-2">
                        <h5>{item.title}</h5>
                        <p>{item.title}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}
