import React from "react";

export default function Footer() {
  return (
    <>
      <footer className="text-center d-flex align-items-end justify-content-center">
        <div>
          <p>© 2023 SmartCarrots. All rights reserved.</p>
        </div>
      </footer>
    </>
  );
}
