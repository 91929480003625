import React, { useContext, useEffect } from "react";
import { blogs } from "../App";
import aboutleft from "../assets/images/about-left.png";
import aboutright from "../assets/images/about-right.png";
import about from "../assets/images/about.png";
import b1 from "../assets/images/b-1.png";
import b2 from "../assets/images/b-2.png";
import b3 from "../assets/images/b-3.png";
import b4 from "../assets/images/b-4.png";
import benfitlogo from "../assets/images/benfit-logo.png";
import group from "../assets/images/Group_75202.png";
import bloglogo from "../assets/images/blogimg.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import "./home.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Footer from "../component/footer";
import ContactUs from "../component/contactus";
import Social_icon from "../component/Social_icon";
import Timer from "../component/Timer";

export default function Home() {
  const bloglist = useContext(blogs);
  const history = useHistory();

  const Viewpage = (item) => {
    history.push({
      pathname: `/Blogdetails/${item._id}/${String(item.title)
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, "-")}`,
    });
  };
  useEffect(() => {
    document.title = "SmartCarrots";
  }, []);

  return (
    <>
      <section className="one">
        <div className="w-100 h-100">
          <div className="nav">
            <img src={group} alt="logo images" className="img-fluid" />
          </div>
          <div className="one-body">
            <div className="text-white text-center">
              <h1>It Pays To Be Smart...</h1>
              <p>
                {/* Join the Cashback Revolution <br /> */}
                {/* Say Something, Earn Something ! */}
                Coming Soon.....
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="about">
        <div className="container-fluid ">
          <Timer />
          <div className="about-body row align-items-center justify-content-center h-100 py-md-5">
            <div className="col-lg-6 text-center">
              <img src={about} alt="" className="img-fluid" />
            </div>
            <div className="col-lg-5 pe-xlg-5 py-lg-5">
              <p className="pe-xlg-5 px-5 pt-5 pb-sm-0 pb-5">
                SmartCarrots offer informative and engaging content about saving
                money, frugal living, and smart shopping tips with cashback.
                Users can read blog posts, articles, and videos that educate
                users on how to make the most of their online shopping
                experience while saving money and paying debts on time. <br />
                <br />
                Also, as the SmartCarrots slogan says - It pays to be smart.
                Indeed being smart can often lead to advantageous outcomes
                regarding, personal finance, saving money, earning money, and
                debt management. We help our members make the best-informed
                decisions by employing strategies that can be financially
                beneficial.
              </p>
            </div>
          </div>
        </div>
        <div className="design">
          <img src={aboutright} alt="" className="about-right-design" />
          <img src={aboutleft} alt="" className="about-left-design" />
        </div>
      </section>

      <section className="benfit">
        <div className="text-center mb-lg-0 mb-5">
          <img src={benfitlogo} alt="" className="ps-5 benfit-log img-fluid" />
        </div>
        <div className="row justify-content-center">
          <div className="col-xxl-9 col-xl-11 col-12 row justify-content-center">
            <div className="col-lg-3 col-sm-6 col-12 pb-5 mt-lg-0 mt-5">
              <div className="card-body">
                <div className="">
                  <div>
                    <img src={b1} alt="images" className="img-fluid " />
                  </div>
                  <p>Top Cashback and Earning Platform</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12 pb-5 mt-lg-0 mt-5">
              <div className="card-body">
                <div className="">
                  <div>
                    <img src={b2} alt="images" className="img-fluid" />
                  </div>
                  <p>
                    Boost Customer Loyalty with{" "}
                    <strong>"Say Something, Earn Something"</strong> Program
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12 pb-5 mt-lg-0 mt-5">
              <div className="card-body">
                <div className="">
                  <div>
                    <img src={b3} alt="images" className="img-fluid" />
                  </div>
                  <p>
                    Expand Your Customer Base as an{" "}
                    <strong>Associate Member</strong>{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12 pb-5 mt-lg-0 mt-5">
              <div className="card-body">
                <div className="">
                  <div>
                    <img src={b4} alt="images" className="img-fluid" />
                  </div>
                  <p>
                    Enhance Business Visibility with{" "}
                    <strong>Preferred Partner Subscription</strong>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="blog pt-4 pb-5">
        <div className="container py-md-5">
          <div className="logo mb-5">
            <img src={bloglogo} alt="" className="img-fluid" />
          </div>
          <div className="container">
            <div className="row px-sm-0 px-3">
              {bloglist?.slice(0, 3)?.map((item) => {
                return (
                  <div
                    className="col-lg-4 col-md-6 mb-3 d-flex justify-content-center"
                    onClick={() => Viewpage(item)}
                  >
                    <div className="blog-body text-center">
                      <div className="blog-img-cont">
                        <img src={item?.image} alt="" className="blog-img" />
                      </div>
                      <h4 className="pt-3 px-5">{item?.title}</h4>
                      <p className="pt-3 px-5">{item.short_description}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="text-center blogbtn mb-5">
            <Link to="/Blog" className="">
              <button className="btn3 m-1">View All</button>
            </Link>
          </div>
        </div>
      </section>

      <ContactUs />

      <section>
        <Social_icon />
      </section>

      <Footer />
    </>
  );
}
