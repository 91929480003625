import { createContext, useEffect, useState } from "react";
import "./App.css";
import Routes from "./routes/routes";

export const blogs = createContext();

function App() {
  const [blogdata, setBlogData] = useState([]);
  async function fetchData() {
    try {
      const response = await fetch(
        "https://smartcarrots.com/user/get_all_blogs"
      );
      const data = await response.json();
      setBlogData(data.data);
      // console.log(blogdata);
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <blogs.Provider value={blogdata}>
        <Routes />
      </blogs.Provider>
    </>
  );
}

export default App;
