import React from "react";
import group from "../assets/images/Group_75202.png";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <div>
      <div className="blog-header">
        <Link to="/Homepage">
          <img src={group} alt="logo images" className="img-fluid" />
        </Link>
      </div>
    </div>
  );
}
