import React from "react";
import { Route } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";

import Home from "../pages/home";
import Blog from "../pages/blog";
import BlogDetails from "../pages/blog-details";

export default function Routes() {
  return (
    <Router>
      <Route exact path="/" component={Home} />
      <Route exact path="/Blog" component={Blog} />
      <Route exact path="/homepage" component={Home} />
      <Route exact path="/Blogdetails/:id/:title" component={BlogDetails} />
    </Router>
  );
}
