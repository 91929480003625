import React, { useState } from "react";
import contactlogo from "../assets/images/contact-logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

export default function ContactUs() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [description, setDescription] = useState("");

  const validateAndSubmit = async (e) => {
    e.preventDefault();

    if (name === "") {
      toast("Please enter Name");
    } else if (email === "") {
      toast("Please write email");
    } else if (!email.includes("@")) {
      toast("Please enter a valid email");
    } else if (phoneNo === "") {
      toast("Please enter phone number");
    } else if (phoneNo.length < 10 || phoneNo.length > 14) {
      toast("Please enter a valid Phone No");
    } else if (description === "") {
      toast("Please write something");
    } else {
      const newphoneNumber = phoneNo.replace(countryCode, "").trim();
      // alert(newphoneNumber);
      const FormData = {
        name: name,
        email: email,
        description: description,
        countryCode: `${countryCode}`,
        mobileNumber: newphoneNumber,
      };

      // alert(phoneNo);
      console.log(FormData);

      try {
        const response = await fetch(
          "https://smartcarrots.com/user/contactUs",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(FormData),
          }
        );

        const responseData = await response.json();
        console.log(responseData);
        toast("Your Form is Submited");
        setName("");
        setDescription("");
        setEmail("");
        setPhoneNo("");
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 50);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <div>
      <section className="contact">
        <div className="container">
          <div className="row h-100">
            <div className="col-xlg-7 col-lg-6 col-md-4  d-flex align-items-end justify-content-end">
              <div className="cont-body text-white">
                <h2>Contact Us</h2>
                <p>
                  Secure exclusive perks by pre-registering for our upcoming
                  website launch. <br /> Don't miss out on enhanced benefits –
                  sign up now and stay ahead of the game!
                </p>
              </div>
            </div>
            <div className="col-xlg-5 col-lg-6 col-md-8">
              <div>
                <form
                  className="px-md-5 px-4 py-5"
                  onSubmit={validateAndSubmit}
                >
                  <div className="mb-3">
                    <label htmlFor="name">Your Name</label> <br />
                    <input
                      type="text"
                      id="name"
                      placeholder="Enter Full Name"
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      value={name}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email">Email</label> <br />
                    <input
                      type="email"
                      id="email"
                      placeholder="smartcarrot@gmail.com"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      value={email}
                      required
                    />
                  </div>

                  {/* <div className="mb-3">
                    <label htmlFor="phone">Mobile Number</label> <br />
                    <PhoneInput
                      disableCountryCode={true}
                      country={"us"}
                      className="w-100"
                      placeholder="Enter phone number"
                      onChange={(value, e) => {
                        setPhoneNo(value);
                        setCountryCode(e.dialCode);
                      }}
                      value={phoneNo}
                      required
                    />
                  </div> */}

                  <div className="mb-3">
                    <label htmlFor="phone">Mobile Number</label> <br />
                    <PhoneInput
                      defaultCountry="US"
                      className="w-100 "
                      placeholder="Enter phone number"
                      onChange={(value) => {
                        setPhoneNo(value);
                      }}
                      value={phoneNo}
                      required
                    />
                  </div>

                  <div>
                    <label htmlFor="description">Ask Question</label> <br />
                    <textarea
                      id="description"
                      placeholder="Ask Question"
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                      value={description}
                      className="ps-2"
                      required
                    ></textarea>
                  </div>
                  <div className="text-center">
                    <div className="mt-3">
                      <button type="submit" className="btn3 mt-3">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <img src={contactlogo} alt="" className="designloog img-fluid" />
        </div>
      </section>
      <ToastContainer />
    </div>
  );
}
