import React from "react";
import insta from "../assets/icon/instagram.png";
import linkdin from "../assets/icon/linkedin.png";
import fb from "../assets/icon/fb.png";
import x from "../assets/icon/x.png";

const Social_icon = () => {
  return (
    <div className="social_icon">
      <a href="https://www.instagram.com/smartcarrots/" target="_blank">
        <img src={insta} alt="" className="img-fluid" />
      </a>

      <a
        href="https://www.facebook.com/people/SmartCarrots/100094310993550/"
        target="_blank"
      >
        <img src={fb} alt="" className="img-fluid" />
      </a>

      <a
        href="https://www.linkedin.com/company/98233258/admin/feed/posts/"
        target="_blank"
      >
        <img src={linkdin} alt="" className="img-fluid" />
      </a>

      <a href="https://twitter.com/SmartCarrots1" target="_blank">
        <img src={x} alt="" className="img-fluid" />
      </a>
    </div>
  );
};

export default Social_icon;
